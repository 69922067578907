import { getRandomString, Exception } from '@tencent/td-open-common-utils';
import { AddonErrorCode, DocsOriginType, ADDON_RESOURCES_DOCS_DOMAIN_MAP } from '@tencent/td-open-common-interfaces';
import urlParser from 'url-parse';

import { AddonMsgToSandbox, SandboxCallbackMsgToAddon, MESSAGE_TYPE, SystemAction } from 'src/common/addon/interface';
import { SYSTEM_ACTIONS } from 'src/common/addon/constant';
import { postParentDocsDomainMessage, verifyDocsDomain } from 'src/common/addon/utils';
import { CALLBACK_TYPE } from 'src/constants/index';
import { ServiceFunction, WithCallbackPromise } from 'src/interface/index';

class AddonClientSDK {
  // 自定义函数的方法
  public app = {
    run: {},
  };
  // 系统提供的方法
  public sys = {
    run: {},
  };
  // 文档 origin
  public parentDocsOrigin: string;

  private callbackMsgFuncMap: Map<string, Function>;

  constructor() {
    this.callbackMsgFuncMap = new Map();
    // 监听文档发送的message事件
    window.addEventListener('message', this.receiveMessage.bind(this));
    this.initParentDocsOrigin();
    this.initSystemFunctions();
    this.initCustomFunctions();
    // 处理localStorage调用异常
    this.handleLocalStorageErr();
  }

  /**
   * 初始化获取父页面 origin
   */
  private initParentDocsOrigin() {
    const { hostname } = urlParser(location.href);
    this.parentDocsOrigin = ADDON_RESOURCES_DOCS_DOMAIN_MAP[hostname] ?? DocsOriginType.DEFAULT;
  }

  /**
   * 初始化系统system方法，挂载插件的系统方法至docs.sys.run
   * 插件开发者调用方法：docs.sys.run.funcX
   */
  private initSystemFunctions() {
    SYSTEM_ACTIONS.forEach((sysName: SystemAction) => {
      Object.defineProperty(this.sys.run, sysName, {
        enumerable: true,
        configurable: true,
        writable: true,
        value: (...rest: any[]) => {
          const msgId = this.generateAndPostMessage(MESSAGE_TYPE.SYSTEM, sysName, rest);
          return this.functionCallbackValue(msgId);
        },
      });
    });
  }

  /**
   * 初始化自定义云函数方法
   */
  private initCustomFunctions() {
    if (window.getServiceFunctions) {
      try {
        const funcArray: ServiceFunction[] = JSON.parse(window.getServiceFunctions());
        const needExportFuncArray = funcArray.filter(item => item.needExport);
        if (needExportFuncArray.length === 0) {
          return;
        }
        needExportFuncArray.forEach(item => {
          this.addCustomFunction(item.funcName, item.funcArgs);
        });
      } catch (err) {
        throw new Exception(AddonErrorCode.FUNC_MOUNT_WINDOWS_ERROR, err);
      }
    }
  }

  private receiveMessage(event: MessageEvent) {
    if (!this.isReceiveMsgValid(event)) {
      return;
    }
    const { ret, msg, data } = event.data as SandboxCallbackMsgToAddon;
    if (!data) {
      return;
    }
    const { msgId, data: resData } = data;
    if (ret === AddonErrorCode.SUCCESS) {
      // 自定义函数成功，回调函数处理，返回data
      this.executeMsgCallback(msgId, CALLBACK_TYPE.SUCCESS_HANDLER, resData);
      return;
    }
    // 自定义函数异常，回调函数处理，返回报错信息msg
    this.executeMsgCallback(msgId, CALLBACK_TYPE.FAILURE_HANDLER, { ret, msg, data: resData });
  }

  /**
   * 校验接收消息是否合法
   * @param event MessageEvent
   * @returns boolean
   */
  private isReceiveMsgValid(event: MessageEvent): boolean {
    // 校验message的来源
    if (!verifyDocsDomain(event.origin)) {
      return false;
    }
    // 校验message内容的合法
    const { ret, data } = event.data as SandboxCallbackMsgToAddon;
    if (data?.msgId && ret !== undefined) {
      return true;
    }
    return false;
  }

  /**
   * 根据收到的消息，执行回调函数
   * @param msgId 消息id
   * @param type 回调类型：成功/失败
   * @param data 消息数据
   */
  private executeMsgCallback(msgId: string, type: CALLBACK_TYPE, data: any) {
    const mapKey = this.getMessageMapKey(msgId, type);
    const func = this.callbackMsgFuncMap.get(mapKey);
    func?.(data);
    func && this.deleteMessageMap(mapKey);
  }

  /**
   * 挂载插件的自定义函数至docs.app.run
   * 插件开发者调用方法：docs.app.run.funcX
   */
  private addCustomFunction(funcName: string, funcArgs: string[]) {
    if (!funcName) {
      return;
    }
    Object.defineProperty(this.app.run, funcName, {
      enumerable: true,
      configurable: true,
      writable: true,
      value: (...rest: any[]) => {
        const msgId = this.invokeMessage(funcName, funcArgs, rest);
        return this.functionCallbackValue(msgId);
      },
    });
  }

  private functionCallbackValue = (msgId: string) => {
    // 支持 Promise 用法
    const callbacks: WithCallbackPromise<any> = new Promise((resolve, reject) => {
      this.setMessageMap(msgId, CALLBACK_TYPE.SUCCESS_HANDLER, resolve);
      this.setMessageMap(msgId, CALLBACK_TYPE.FAILURE_HANDLER, reject);
    });
    // 支持 callback 用法
    // callback 会覆盖 MessageMap 中 Promise 的 resolve 和 reject , 使 Promise 一直处于 pending 状态
    // 不支持混用
    callbacks[CALLBACK_TYPE.SUCCESS_HANDLER] = (SuccFunc: Function) => {
      this.setMessageMap(msgId, CALLBACK_TYPE.SUCCESS_HANDLER, SuccFunc);
      return callbacks;
    };
    callbacks[CALLBACK_TYPE.FAILURE_HANDLER] = (FailFunc: Function) => {
      this.setMessageMap(msgId, CALLBACK_TYPE.FAILURE_HANDLER, FailFunc);
      return callbacks;
    };
    return callbacks;
  };

  /**
   * 自定义函数执行invoke调用：通过postMessage实现
   */
  private invokeMessage(funcName: string, funcArgs: string[], args: unknown[]): string {
    if (funcArgs.length !== args.length) {
      throw new Exception(AddonErrorCode.FUNC_ARGUMENTS_ILLEGAL);
    }
    const msgId = this.generateAndPostMessage(MESSAGE_TYPE.CUSTOM, funcName, args);
    return msgId;
  }

  /**
   * 生成信息，通过postMessage方式发送
   */
  private generateAndPostMessage(type: MESSAGE_TYPE, name: string, args: unknown[]): string {
    const msgId = this.generateMsgId();
    const postData: AddonMsgToSandbox = {
      type,
      msgId,
      funcName: name,
      funcArgs: args,
      href: window.location.href,
    };
    // TODO(fugao): 因为司内的合作伙伴只做了文档域名私有化,没有做插件域名私有化,目前无法走配置,只能先用*,后续处理 tapd:871859973
    postParentDocsDomainMessage(postData, '*');
    return msgId;
  }

  /**
   * callbackMsgFuncMap：生成并添加msgId（回调函数）
   */
  private setMessageMap(msgId: string, type: CALLBACK_TYPE, func: Function) {
    const mapKey = this.getMessageMapKey(msgId, type);
    this.callbackMsgFuncMap.set(mapKey, func);
  }

  /**
   * callbackMsgFuncMap：删除msgId（回调函数）
   */
  private deleteMessageMap(key: string) {
    this.callbackMsgFuncMap.delete(key);
  }

  /**
   * 根据时间戳和随机数，生成msgId
   */
  private generateMsgId(): string {
    const time = new Date().getTime();
    // 随机数长度为4位
    const randomLen = 4;
    const random = getRandomString(randomLen);
    return `${time}${random}`;
  }

  private getMessageMapKey(msgId: string, type: CALLBACK_TYPE): string {
    return `${msgId}_${type}`;
  }

  private handleLocalStorageErr() {
    try {
      window.localStorage.getItem;
    } catch (err) {
      console.error('LocalStorageError', err);
      /** 对 localStorage 做覆写
       * 覆写原因: 由于插件域名与文档域名不同，在chrome无恒且阻止第三方cookie的情况下会出现window.localStorage访问异常
       * 因此在client-sdk中做一个覆写处理
       */
      const localStorageMock = (() => {
        let store = {};
        return {
          getItem(key) {
            return store[key] || null;
          },
          setItem(key, value) {
            store[key] = value.toString();
          },
          removeItem(key) {
            delete store[key];
          },
          clear() {
            store = {};
          },
        };
      })();
      Object.defineProperty(window, 'localStorage', {
        value: localStorageMock,
        configurable: true,
        enumerable: true,
        writable: true,
      });
    }
  }
}

window.docs = new AddonClientSDK();

export default AddonClientSDK;
