import { SystemAction } from './interface';

export const SYSTEM_ACTIONS = [
  SystemAction.CLOSE,
  SystemAction.UPLOAD_IMAGE,
  SystemAction.IMPORT_SLIDE,
  SystemAction.CALL_OCR,
  SystemAction.UPDATE_SIDEBAR,
  SystemAction.UPDATE_DIALOG,
  SystemAction.REPORT,
  SystemAction.PAY,
];
