import urlParser from 'url-parse';
import { Exception } from '@tencent/td-open-common-utils';
import { AddonErrorCode } from '@tencent/td-open-common-interfaces';

/**
 * postMessage发送至（父页面）文档域名
 * @param postData 发送消息数据
 */
export function postParentDocsDomainMessage(postData: unknown, origin: string) {
  if (origin !== '*') {
    const valid = verifyDocsDomain(origin);
    if (!valid) {
      throw new Exception(AddonErrorCode.MESSAGE_RESOURCE_ILLEGAL);
    }
  }
  window.parent.postMessage(postData, origin);
}

/**
 * 校验是否为文档域名
 * @param origin
 * @returns
 */
export function verifyDocsDomain(origin: string): boolean {
  // TODO(cikeypeng): 后续若要支持非qq.com、tencent.com域名，需要重新处理
  const originRegex = /^https:\/\/([a-zA-Z0-9]+\.)+(qq|tencent)\.com/;
  return originRegex.test(origin);
}

/**
 * 动态获取父窗口 origin
 * referrer 获取不到时降级使用 *
 */
export function getParentOriginDynamically(): string {
  if (document.referrer) {
    return urlParser(document.referrer)?.origin ?? '*';
  }
  return '*';
}
