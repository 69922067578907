import { AddonErrorCode } from '@tencent/td-open-common-interfaces';

export enum MESSAGE_TYPE {
  /** 系统能力 */
  SYSTEM = 0,
  /** 自定义云函数能力 */
  CUSTOM = 1,
}

/**
 * 系统提供的方法
 */
export enum SystemAction {
  CLOSE = 'close',
  UPLOAD_IMAGE = 'uploadImage',
  IMPORT_SLIDE = 'importSlide',
  CALL_OCR = 'callOCR',
  UPDATE_DIALOG = 'updateDialog',
  UPDATE_SIDEBAR = 'updateSidebar',
  REPORT = 'report',
  PAY = 'pay',
}

/**
 * 第三方插件侧发送sandbox的消息
 */
export interface AddonMsgToSandbox {
  /* 1、插件消息的类型 */
  type: MESSAGE_TYPE;
  /* 2、插件消息发送ID */
  msgId: string;
  /* 3、插件调用函数方法名 */
  funcName: string;
  /* 4、插件调用函数传递参数 */
  funcArgs: unknown[];
  /* 5、插件消息发送地址 */
  href: string;
}

/**
 * 文档侧发送sandbox的回包消息
 */
export interface DocsMsgDataToSandbox {
  /* 回包消息的类型 */
  type: MESSAGE_TYPE;
  /* 回包携带的插件消息发送ID */
  msgId: string;
  /* 回包返回的数据 */
  data?: unknown;
}

/**
 * sandbox发送第三方插件侧的回包消息(即：文档侧发送sandbox的回包消息)
 */
export interface SandboxCallbackMsgToAddon extends CallbackRes<DocsMsgDataToSandbox> {}

export interface CallbackRes<T = unknown> {
  ret: AddonErrorCode;
  msg?: string;
  data?: T;
}
